<template>
  <div class="add-contactUser">
    <LoadingOverlay v-if="isFetchingFilter" />
    <div v-else>
      <h3 class="text-primary main-title m-1 pt-1">Add User</h3>
      <ValidationObserver ref="addContactUser" v-slot="{ handleSubmit }">
        <form id="addContactUser" @submit.prevent="handleSubmit(onSubmit)">
          <CCard class="mt-2">
            <CCardBody>
              <CRow>
                <CCol md="6">
                  <CRow name="isUser" class="mb-3">
                    <label class="col-lg-12 col-md-12 required">Type</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <RadioButton name="isUser" :value="user.isUser" :options="
                          options && options['customerUser']
                            ? options['customerUser']
                            : []
                        " @change="handleChangeRadio" :error="errors[0]" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow>
                    <label class="col-lg-12 col-md-12 required">Organisation</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select name="organisation_id" :value="user.organisation_id" :options="
                          options && options['organisations']
                            ? options['organisations']
                            : []
                        " :multiple="false" :clearable="false" @input="handleChangeSelect" :error="errors[0]" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <!-- <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Select the type of User</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="customer_user_type_ids"
                          :value="user.customer_user_type_ids"
                          :options="
                            options && options['user_type']
                              ? options['user_type']
                              : []
                          "
                          :multiple="true"
                          :clearable="false"
                          @input="handleChangeMultiSelectAsArray"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol> -->
                <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required">Job Title / Level</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select name="contact_type_id" :value="user.contact_type_id" :options="
                          options && options['contact_type']
                            ? options['contact_type']
                            : []
                        " :multiple="false" :clearable="false" @input="handleChangeSelect" :error="errors[0]" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="department" class="mb-3">
                    <label class="col-lg-12 col-md-12">Department</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select name="department_id" :value="user.department_id" :options="
                        options && options['department']
                          ? options['department']
                          : []
                      " :multiple="false" :clearable="false" @input="handleChangeSelect" />
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6" v-if="isUser">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12"
                      >Select the type of User</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider v-slot="{ errors }">
                        <Select
                          name="customer_user_type_ids"
                          :value="user.customer_user_type_ids"
                          :options="
                            options && options['user_type']
                              ? options['user_type']
                              : []
                          "
                          :multiple="true"
                          :clearable="false"
                          @input="handleChangeMultiSelectAsArray"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard class="mt-2">
            <CCardBody>
              <h5 class="pb-2 text-primary">User Details</h5>
              <CRow>
                <CCol lg="6">
                  <CRow name="first_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12">First Name</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput name="first_name" :value="user.first_name" :error="errors[0]" @input="handleInput" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="last_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Last Name</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput name="last_name" :value="user.last_name" :error="errors[0]" @input="handleInput" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow name="phone" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Phone</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider ref="phone" :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }" v-slot="{ errors }">
                        <PhoneInput name="phone" :value="user.phone" :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        " :error="errors[0]" @change="handleInput" ref="phoneNo" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="email" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Email</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <TextInput name="email" :value="user.email" :error="errors[0]" @input="handleInput"
                          autocomplete="new-email" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow v-if="isUser">
                <CCol md="6">
                  <CRow name="password" class="mb-3">
                    <label class="col-lg-12 col-md-12 required">Password</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider name="password" :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }" v-slot="{ errors }">
                        <PasswordInput name="password" :value="user.password" @input="handleInput" :error="errors[0]"
                          autocomplete="new-password" />
                        <small class="hint">
                          Password format: 8 characters - containing upper &
                          lower case letters, numbers and a special character.
                          No words.
                        </small>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow name="confirm_password" class="row mb-3">
                    <label class="col-lg-12 col-md-12">Confirm Password</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" ref="confirmPassword">
                        <PasswordInput name="confirmPassword" :value="confirmPassword" @input="handleConfirmPassword"
                          :error="errors[0]" />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <div class="text-center mx-0 mt-2 pt-4 action-area">
            <CButton variant="outline" size="lg" type="button" color="primary" shape="square" class="px-5 m-1 mt-3"
              @click="resetForm">Clear All</CButton>
            <CButton type="submit" size="lg" color="primary" shape="square" class="px-5 m-1 mt-3">Submit</CButton>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
import { isEmptyObjectCheck, isObject } from "../../helpers/helper";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
    RadioButton,
  },
  data() {
    return {
      user: {},
      payload: {},
      confirmPassword: null,
      customerRecruiterOpt: { code: 0, label: "Customer Recruiter" },
    };
  },
  computed: {
    ...mapGetters([
      "userTypes",
      "dialingCodeWithCountryCode",
      "contactType",
      "isFetchingFilter",
      "getDepartmentsByOrganisationId",
      "getUser",
      "organizationId",
      "getOrgIDFromAccessToken",
    ]),
    options() {
      return {
        user_type: this.customUserType || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        contact_type: this.contactType || [],
        customerUser: [
          { id: true, label: "User" },
          { id: false, label: "Contact" },
        ],
        department: this.getDepartmentsByOrganisationId || [],
        organisations: this.organizationId || [],
      };
    },
    customUserType() {
      const userTypes = [...this.userTypes, this.customerRecruiterOpt];
      return this.payload.customer_user_type_ids &&
        userTypes &&
        userTypes.length
        ? userTypes.filter(
          (val) => !this.payload.customer_user_type_ids.includes(val?.code)
        )
        : userTypes;
    },
    isUser() {
      return this.payload?.isUser || false;
    },
  },
  methods: {
    ...mapActions(["initContactFetch", "showToast", "fetchUser"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleChangeMultiSelectAsArray(name, value) {
      // const newInput =
      //   value.filter(
      //     (v) =>
      //       this.user[name] &&
      //       !this.user[name].some((val) => val?.code === v?.code)
      //   ) || [];

      // const alreadyRecruiter =
      //   this.user[name] && this.user[name].some((v) => v?.code === 0);
      // const val = newInput.length
      //   ? newInput[0].code === 0
      //     ? [this.customerRecruiterOpt]
      //     : alreadyRecruiter
      //     ? newInput
      //     : value
      //   : value;
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
            return v.id || v.code;
          })
          : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    resetForm() {
      this.user = {};
      this.payload = {};
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.addContactUser.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        contact_type_id: this.payload?.contact_type_id,
        department_id: this.payload?.department_id,
        first_name: this.payload?.first_name,
        last_name: this.payload?.last_name,
        phone: this.payload?.phone,
        email: this.payload?.email,
        organisation_id: this.payload?.organisation_id,
      };
      if (this.isUser) {
        const customer_users =
          this.payload?.customer_user_type_ids.filter((v) => v) || [];
        const customer_recruiters = this.payload?.customer_user_type_ids.filter(
          (v) => v === this.customerRecruiterOpt.code
        ) || [];
        finalPayload = { ...finalPayload, customer_users, customer_recruiters };
      }
      if (this.isUser) {
        finalPayload = { ...finalPayload, password: this.payload?.password };
      }
      this.$emit("submit", finalPayload);
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then(async () => {
      await this.initContactFetch();
      const organisation = this.organizationId.filter(
        ({ code }) => code === this.getOrgIDFromAccessToken
      );
      if (organisation.length) {
        this.handleChangeSelect("organisation_id");
      }
    });
    this.handleChangeRadio("isUser", true);
  },
};
</script>
<style lang="scss" scoped>
.action-area {
  .btn {
    width: 172px;
  }
}
</style>